import { useState, useEffect } from 'react';

interface CustomEventDetail<T> {
  key: string;
  value: T;
}

const useSessionStorage = <T>(key: string, initialValue: T): [T, (val: T) => void] => {
  const [storedValue, setStoredValue] = useState<T>(() => {
    if (typeof window === 'undefined') {
      return initialValue;
    }

    try {
      // Check if there's a value in sessionStorage
      const item = window.sessionStorage.getItem(key);
      // Parse stored json or return initialValue
      return item ? JSON.parse(item) : initialValue;
    } catch (error) {
      return initialValue;
    }
  });

  useEffect(() => {
    // Event handler for custom events to sync sessionStorage changes within the same tab
    const handleInternalStorageEvent = (event: CustomEvent<CustomEventDetail<T>>) => {
      if (event?.detail?.key === key) {
        setStoredValue(event?.detail?.value);
      }
    };

    // eslint-disable-next-line no-undef
    window.addEventListener(`internalStorage:${key}`, handleInternalStorageEvent as EventListener);

    return () => {
      window.removeEventListener(
        `internalStorage:${key}`,
        // eslint-disable-next-line no-undef
        handleInternalStorageEvent as EventListener
      );
    };
  }, [key, initialValue]);

  const setValue = (value: T) => {
    try {
      // Allow value to be a function so we can have similar API as useState
      const valueToStore = value instanceof Function ? value(storedValue) : value;
      // Save state
      setStoredValue(valueToStore);
      // Save to sessionStorage
      if (typeof window !== 'undefined') {
        window.sessionStorage.setItem(key, JSON.stringify(valueToStore));
      }

      const event = new CustomEvent<CustomEventDetail<T>>(`internalStorage:${key}`, {
        detail: { key, value: valueToStore },
      });
      window.dispatchEvent(event);
      return;
    } catch (error) {
      return;
    }
  };

  return [storedValue, setValue];
};

export default useSessionStorage;
